import React, { useState, useEffect } from 'react';
import Cookie from 'js-cookie';

import useSWR from 'swr';
import { useLocation } from '@reach/router';
import { END_POINT } from '../../helpers';
// import SimpleBarReact from 'simplebar-react';

import Api from '@api';
import SEO from '@components/seo';
import Icon from '@components/atoms/icon';
import Button from '@components/atoms/button';
import { useMedia } from 'use-media';

import CardLayout from '@components/templates/create-card/views/preview/card-layout';
import Modal from '@components/molecules/modal';
import { hasSocials, isCurator, handleAspectRatio } from '@helpers';
import Card from '@components/organisms/card';
import { isVideoType } from '@components/organisms/card/shared/helpers';

import {
  pageWrapper,
  publicHeader,
  visitSite,
  joinContentCreator,
  publicWrapper,
  openedWrapper,
  layoutParentWrap,
  publicFooter,
  // closeCardView,
  downloadOnIos,
  downloadLetters,
  mobileLinks,
  impressumBottomLink,
} from './sharedStyles.module.scss';

const SharedCard = ({ params }) => {
  const cardSecret = 'cards_43f472e4-8156-4d49-9aaa-7e8aa3ccf27a';
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [url, setUrl] = useState(END_POINT[3]);
  const { data, error } = useSWR(
    ['/getPublicCardData', url, params?.cardId, params?.tenantId],
    () => Api.getPublicCardData(url, cardSecret, params?.cardId, params?.tenantId)
  );

  const { data: impressumData, error: errorData } = useSWR(
    ['/getProfile', url, cardSecret, data?.userId],
    () => Api.getPublicProfileData(url, cardSecret, data?.userId)
  );

  const isTablet = useMedia({ maxWidth: '992px' });

  const CardProps = () => {
    const cardObject = {
      key: data && data?.cardId,
      id: data && data?.cardId,
      title: data && data?.title,
      sharedCard: true,
      shareable: true,
      description: data && data?.description,
      src: data && data?.coverMedia?.items && data?.coverMedia?.items[0],
      channelId: data && data?.streamId,
      channel: data && data?.stream,
      curator: isCurator.includes(data?.subFormat),
      cardClass: data && data?.subFormat,
      category: data && data?.category,
      contentSourceProvider: data && data?.contentSourceProvider,
      type: data && data?.subFormat,
      date: data && data?.created,
      author: data && data?.author,
      isPiqd: data && data?.contentSourceProvider === 'piqd',
      coverAuthor: data && data?.coverAuthor,
      playable: data && data?.subFormat ? isVideoType.includes(data?.subFormat) : '',
      preview: true,
      public: true,
      socials: hasSocials.includes(data?.subFormat) ? data?.counters : null,
      intendedAspectRatio: handleAspectRatio(data?.media, data?.subFormat),
    };

    return data ? cardObject : null;
  };

  const openLayoutProps = {
    media: data && data?.coverMedia?.items,
    title: data && data?.title,
    author: data && data?.author,
    counters: data && data?.counters,
    date: data && data?.latestDatePublished,
    isPiqd: data && data?.contentSourceProvider === 'piqd',
    innerAuthor: data && data?.innerAuthor,
    description: data && data?.description,
    subFormat: data && data?.subFormat,
    content: data && data?.content_v2,
    hasScrollbar: true,
    maxHeight: isTablet ? 'calc(100vh - 15rem)' : 'calc(100vh - 22rem)',
    maxWidth: '35rem',
  };

  const checkEnvironmentOrigin = () => {
    const routePath = location.origin;
    const preselected = Cookie.get('env');

    if (preselected) {
      setUrl(END_POINT[preselected]);
    } else if (routePath.includes('content')) {
      setUrl(END_POINT[3]);
    } else if (routePath.includes('test-ccd')) {
      setUrl(END_POINT[1]);
    } else if (routePath.includes('preprod-ccd')) {
      setUrl(END_POINT[2]);
    } else {
      setUrl(END_POINT[0]);
    }
  };

  useEffect(() => {
    checkEnvironmentOrigin();
  }, []);

  return (
    <>
      <SEO title={`${data && data.title} - Public Card`} />
      {showModal && (
        <Modal
          type="impressum"
          title={'Impressum/Imprint'}
          desc={impressumData?.impressum}
          showModal={showModal}
          onCancel={() => setShowModal(false)}
        />
      )}
      <div className={pageWrapper}>
        <div className={publicHeader}>
          <a
            href="https://justt.me/"
            target="_blank"
            title="Go to our Website"
            className={visitSite}
          >
            <Icon iconClass="globe" />
            <p>Visit Website</p>
          </a>
          <svg height={30} viewBox="0 0 95 30" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0 -9)" fill="#000" fillRule="evenodd">
              <path
                d="M10.137 38.586c6.035 0 9.629-3.418 9.629-9.414V9.816h-5.899v19.317c0 2.832-1.328 4.355-3.789 4.355-2.226 0-3.71-1.504-3.75-3.789H.605c0 5.39 3.77 8.887 9.532 8.887zm21.738-.273c3.262 0 5.371-1.504 6.465-4.2h.117V38h5.508V16.496h-5.703v12.246c0 2.91-1.66 4.824-4.317 4.824-2.636 0-4.082-1.562-4.082-4.472V16.496H24.16v13.79c0 5 3.067 8.026 7.715 8.026zm24.57.058c5.625 0 9.473-2.754 9.473-6.934 0-3.203-1.719-4.863-6.035-5.84l-4.043-.878c-1.934-.45-2.871-1.172-2.871-2.246 0-1.387 1.367-2.305 3.418-2.305 2.187 0 3.613 1.074 3.73 2.773h5.274c-.079-4.101-3.614-6.816-8.926-6.816-5.45 0-9.004 2.656-9.004 6.7 0 3.144 2.09 5.234 6.133 6.152l3.965.898c1.953.469 2.695 1.055 2.695 2.168 0 1.406-1.387 2.285-3.652 2.285-2.364 0-3.829-1.016-4.102-2.832h-5.566c.293 4.14 3.847 6.875 9.511 6.875zm20.899-.293c1.152 0 2.168-.117 2.754-.234v-4.219a9.798 9.798 0 01-1.426.098c-1.738 0-2.559-.801-2.559-2.461v-10.45h3.985v-4.316h-3.985v-4.785H70.41v4.785H67.5v4.317h2.91v11.386c0 4.2 1.953 5.88 6.934 5.88zm14.472 0c1.153 0 2.168-.117 2.754-.234v-4.219a9.798 9.798 0 01-1.425.098c-1.739 0-2.56-.801-2.56-2.461v-10.45h3.985v-4.316h-3.984v-4.785h-5.703v4.785h-2.91v4.317h2.91v11.386c0 4.2 1.953 5.88 6.933 5.88z"
                fill="var(--logo-color)"
                fillRule="nonzero"
              />
              <circle fill="#00FF24" cx="4" cy="14" r="4" />
            </g>
          </svg>

          <a
            href="mailto:justtcreators@justt.me?subject=Join as a Content Creator"
            className={joinContentCreator}
          >
            <p>Join as Content Creator</p>
            <Icon iconClass="link-image" />
          </a>
        </div>
        <div className={`${publicWrapper} ${open ? !openedWrapper : ''}`}>
          {data && !open ? (
            <div onClick={() => (data.format === 'Indepth' ? setOpen(!open) : '')}>
              {data.format === 'Indepth' ? <h4>Press to open</h4> : ''}
              <Card {...CardProps()} />
            </div>
          ) : (
            ''
          )}
          {open && (
            <div className={layoutParentWrap}>
              <Button onClick={() => setOpen(false)}>
                <Icon iconClass="close-thin" />
                <span>Press to close card view</span>
              </Button>

              <CardLayout {...openLayoutProps} />
            </div>
          )}
          <div className={impressumBottomLink}>
            <Button onClick={() => setShowModal(!showModal)}>Impressum</Button>
          </div>
        </div>
        <div>
          <div className={publicFooter}>
            <h3>Get Justt for iOS to unlock the full experience!</h3>
            <a
              href="https://apps.apple.com/app/id1576473812"
              target="_blank"
              className={downloadOnIos}
            >
              <svg width="17px" height="21px" viewBox="0 0 256 315" version="1.1">
                <g>
                  <path
                    d="M213.803394,167.030943 C214.2452,214.609646 255.542482,230.442639 256,230.644727 C255.650812,231.761357 249.401383,253.208293 234.24263,275.361446 C221.138555,294.513969 207.538253,313.596333 186.113759,313.991545 C165.062051,314.379442 158.292752,301.507828 134.22469,301.507828 C110.163898,301.507828 102.642899,313.596301 82.7151126,314.379442 C62.0350407,315.16201 46.2873831,293.668525 33.0744079,274.586162 C6.07529317,235.552544 -14.5576169,164.286328 13.147166,116.18047 C26.9103111,92.2909053 51.5060917,77.1630356 78.2026125,76.7751096 C98.5099145,76.3877456 117.677594,90.4371851 130.091705,90.4371851 C142.497945,90.4371851 165.790755,73.5415029 190.277627,76.0228474 C200.528668,76.4495055 229.303509,80.1636878 247.780625,107.209389 C246.291825,108.132333 213.44635,127.253405 213.803394,167.030988 M174.239142,50.1987033 C185.218331,36.9088319 192.607958,18.4081019 190.591988,0 C174.766312,0.636050225 155.629514,10.5457909 144.278109,23.8283506 C134.10507,35.5906758 125.195775,54.4170275 127.599657,72.4607932 C145.239231,73.8255433 163.259413,63.4970262 174.239142,50.1987249"
                    fill="#4d4d4d"
                  ></path>
                </g>
              </svg>
              <div className={downloadLetters}>
                <span>Download on the</span>
                <h4>App Store</h4>
              </div>
            </a>
          </div>
          <div className={mobileLinks}>
            <a
              href="https://justt.me/"
              target="_blank"
              title="Go to our Website"
              className={visitSite}
            >
              <Icon iconClass="globe" />
              <p>Visit Website</p>
            </a>
            <a
              href="mailto:justtcreators@justt.me?subject=Join as a Content Creator"
              className={joinContentCreator}
            >
              <Icon iconClass="link-image" />
              <p>Join as Content Creator</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedCard;
